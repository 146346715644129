/* eslint-disable no-unreachable */
import { Auth } from 'aws-amplify'
import { notification } from 'antd'
import store from 'store'
import { errorMessageConstant } from '../../constants/message.constants'
import axios from 'axios';

export async function login(email, password) {
	const response = await axios.get('https://api.ipify.org?format=json');
	const ipaddress = response.data.ip;

	console.log(ipaddress,"ipAddreaaaaaassss")

	return Auth.signIn(email, password, { ipaddress: ipaddress, source: 'ADMINPORTAL' })
		.then((authReponse) => {
			if (authReponse) {
				console.log('authReponse: ', authReponse)
				store.set('authToken', authReponse?.signInUserSession?.idToken?.jwtToken)
				notification.success({
					message: 'Logged In',
					description: 'You have successfully logged in!',
					duration: 1.2,
				})
				return authReponse
			}
			return false
		})
		.catch(() => {
			notification.info({
				message: `You have entered an invalid username or password`,
				description: 'Please try again',
			})
		})
}

export async function register(payload) {
	console.log(payload, 'payloadddd')

	const response = await axios.get('https://api.ipify.org?format=json');
	const ipaddress = response.data.ip;

	console.log(ipaddress,"ipAddreaaaaaassss in payloaddddd")


	return Auth.signUp(
		{
			username: payload.email,
			password: payload.password,
			attributes: {
				email: payload.email,
				phone_number: `+1${payload.phone_number}`,
				'custom:userType': '6',
				name: `${payload.firstname} ${payload.lastname}`,
			},
		},
		{ ipaddress: ipaddress, source: 'ADMINPORTAL' },
	)
		.then((authReponse) => {
			if (authReponse) {
				console.log('register Response: ', authReponse)
				// store.set('authToken', authReponse?.signInUserSession?.idToken?.jwtToken)
				return authReponse
			}
			return false
		})
		.catch((err) => {
			// eslint-disable-next-line no-underscore-dangle
			// if(err.Status Code === 'UsernameExistsException'){
			// 	notification.info({
			// 		message: `An account with the given email already exists.`,
			// 		description: 'Please try again',
			// 	})
			// }else{
			notification.info({
				message: err.message,
				description: 'Please try again',
			})
			// }
		})
}

export function currentAccount() {
	// const authResponse = store.get('cognitoResponse')'
	return Auth.currentAuthenticatedUser()
		.then((response) => {
			console.log('currentAuthenticatedUser response: ', response)
			if (response) {
				store.set('authToken', response?.signInUserSession?.idToken?.jwtToken)
				const userDetails =
					(response && response.attributes && { ...response.attributes, id: response.username }) ||
					{}
				// store.set('accessToken', accessToken)
				return userDetails
			}
			return false
		})
		.catch((err) => console.log(err))
}

export async function logout() {
	store.remove('cognitoResponse')
	store.remove('authToken')
	await Auth.signOut()
}

export async function completeNewPassword(user, newPassword) {
	try {
		const cognitoUser = await Auth.completeNewPassword(user, newPassword)
		console.log('cognitoUser: ', cognitoUser)
		if (cognitoUser) {
			return true
		}
		return false
	} catch (error) {
		console.log('error: ', error)
		return false
	}
}

export async function forgotPasswordSubmit(user, code, newPassword) {
	try {
		const cognitoUser = await Auth.forgotPasswordSubmit(user, code, newPassword)
			.then((data) => {
				console.log(data)
				return true
			})
			.catch((err) => {
				console.log(err)
				return false
			})
		if (cognitoUser) {
			return true
		}
		return false
	} catch (error) {
		console.log('error: ', error)
		return false
	}
}
export async function forgotPassword(email) {
	try {
		const cognitoUser = await Auth.forgotPassword(email)
		console.log('cognitoUser: ', cognitoUser)
		if (cognitoUser) {
			return true
		}
		return false
	} catch (error) {
		console.log('error: ', error)
		return false
	}
}

export async function changePassword(oldPassword, newPassword) {
	try {
		const user = await Auth.currentAuthenticatedUser()
		if (user) {
			const changedPasswordResponse = await Auth.changePassword(user, oldPassword, newPassword)
			if (changedPasswordResponse) {
				return true
			}
			return false
		}
		return false
	} catch (error) {
		console.log('error : ', error)
		notification.warning({
			message: errorMessageConstant[error.code],
			description: errorMessageConstant[error.message],
		})
		return false
	}
}
